<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout row wrap class="layout header_bg" v-resize="updateDataTableHeight" fluid>
      <v-flex xs12 class="row_title">
        <v-layout row style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          " class="grey lighten-5">
          <v-flex xs4 class="text-left ml-0 pl-0 mb-2 mt-1">
            <v-btn icon @click="OnBackToPages()">
              <v-icon color="pink lighten-1" large>mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            ดูโพย

            <!-- <v-btn color="pink lighten-1" class="" outlined><v-icon left> mdi-arrow-left</v-icon> ดูโพย</v-btn> -->
          </v-flex>

          <v-flex xs8 class="text-right mr-1 mt-2 mr-2 pink--text text--darken-1" v-if="CloseTime != ''">
            ปิดรับ {{ getAbbrPeriodThai(CloseTime.split(" ")[0]) }} เวลา
            {{ CloseTime.split(" ")[1].split(":")[0] }}:{{
              CloseTime.split(" ")[1].split(":")[1]
            }}
            น.</v-flex>
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
        <v-layout row style="background-color: GhostWhite; flex-flow: row" class="py-2">
          <v-flex xs12 class="text-left">
            <span class="pl-1">รหัส {{ HostID }}{{ LineID }}{{ AgentID }}
              <span v-if="CusID != '001'">{{ CusID }}</span>
            </span>
          </v-flex>
          <v-flex xs12 class="text-right mr-2">
            <span v-if="CusID != '001'">ชื่อลูกค้า</span>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row style="background-color: GhostWhite; flex-flow: row" class="py-2">
          <v-flex xs7 class="text-left"><span class="pl-1">ใบที่ {{ PageNumber }}</span><span class="pl-2"
              :class="getStatusColor(PageStatus)">({{ GetStatusText(PageStatus) }})</span>
          </v-flex>
          <v-flex xs5 class="text-right mr-2">ลอย {{ add_comma(PriceLoy) }} รวม {{ add_comma(PriceSum) }}
          </v-flex>
        </v-layout>
        <v-divider></v-divider>

        <v-layout row style="background-color: GhostWhite; flex-flow: row" class="py-1">
          <v-btn id="menu-activator" color="blue lighten-2" dark @click="updateDataTableHeight()">
            <v-icon left> mdi-percent-circle </v-icon>
            ดูเลขอั้น
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn :color="PageStatus === 0 ? 'pink lighten-2' : 'grey'" dark
            @click="PageStatus === 0 ? OnAddLine() : ''">
            <v-icon left> mdi-plus-box </v-icon>
            เพิ่มบรรทัด
          </v-btn>
          <!-- PageStatus -->

          <!-- <v-btn
            :color="PeriodIsOpen===0? 'grey' : 'pink lighten-2'"
            dark
            @click="PeriodIsOpen===0? '' : OnAddPage()"
          >
            <v-icon left> mdi-plus-box </v-icon>
            เพิ่มโพยใหม่
          </v-btn> -->
          <!-- <v-flex xs7 class="text-left"><span class="pl-1">ใบที่ 1</span><span class="pl-2">(รอส่ง)</span>  </v-flex>
          <v-flex xs5 class="text-right">รวม 100 ลอย 100 </v-flex> -->
        </v-layout>
        <v-divider></v-divider>

        <!-- <v-toolbar flat dense>
          <v-row class="my-0 mx-0" ref="page_toolbar">
            <v-btn outlined id="menu-activator" color="lime darken-4" dark>
              <v-icon left> mdi-percent-circle </v-icon>
              ดูเลขอั้น
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn outlined color="pink darken-3" dark @click="OnAddLine()">
              เพิ่มบรรทัด
              <v-icon right> mdi-plus-box </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar> -->
      </v-flex>
      <v-flex xs12 class="row_content" ref="tableDiv">
        <Table :tableheight="dataTableHeight" :CusID="CusID" :PageNumber="parseInt(PageNumber)" :UpdateDT="UpdateDT"
          ref="tblPage"></Table>
      </v-flex>
      <v-flex xs12 class="row_footer">
        <v-btn v-if="PageStatus == 0" @click="OnSendPage()" block color="blue lighten-2" dark>ส่ง</v-btn>
        <v-layout v-if="PageStatus == 1 || PageStatus == 3 || PageStatus == 4" row
          style="background-color: GhostWhite; flex-flow: row" class="py-1">
          <v-flex xs6>
            <v-btn color="green lighten-2" dark @click="OnPrintBtn_Click()" block>
              <v-icon left> mdi-printer</v-icon>
              พิมพ์
            </v-btn></v-flex>
          <v-flex xs6>
            <v-btn color="blue lighten-2" dark @click="OnShareBtn_Click()" block>
              <v-icon left> mdi-share-variant </v-icon>
              แชร์
            </v-btn></v-flex>
        </v-layout>

        <v-btn v-if="PageStatus == -1" block dark>กู้โพยคืน</v-btn>
        <!-- <v-btn v-else-if="PageStatus==2"  block dark>กู้โพยคืน</v-btn> -->
        <!-- <v-btn  block dark>กู้โพยคืน</v-btn> -->
        <!-- PageStatus===0? OnAddLine() : '' -->
      </v-flex>
    </v-layout>
    <ConfirmHPDlg @OnConfirmBet="OnConfirmBet" ref="ConfirmHPDlg" />
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import Table from "@/components/Pages/Page/Table";
import ConfirmHPDlg from "@/components/Pages/Page/ConfirmHPDlg";

import iconv from 'iconv-lite';

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    Table,
    ConfirmHPDlg,
    //component name,
  },
  computed: {
    // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    PriceSum() {
      return parseInt(this.$store.getters["page/PageData"].PriceSum);
    },
    PriceLoy() {
      return parseInt(this.$store.getters["page/PageData"].PriceSumLoy);
    },
    PageStatus() {
      return parseInt(this.$store.getters["page/PageData"].Status);
    },
    UpdateDT() {
      return this.$store.getters["page/PageData"].UpdateDT;
    },
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    this.PageNumber = this.$route.query.PageNumber;
    this.CusID = this.$route.query.CusID;
    // this.updateDataTableHeight();
    setTimeout(() => {
      this.updateDataTableHeight();
    }, 500);
    this.GetDataTable();
  },
  methods: {
    async GetDataTable() {
      let parameter = {
        to: "Page",
        method: "get_all",
        PeriodDT: this.PeriodDT,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.CloseTime = response.Period.CloseTime;
          this.PeriodStatus = parseInt(response.Period.Status);
          this.PeriodIsOpen = parseInt(response.Period.IsOpen);

          this.$refs.tblPage.setFocus();
        },
        (error) => { }
      );
    },
    getPrice1(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[0];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[0];
      } else {
        return p_PriceText;
      }
    },
    OnSendPage() {
      if (this.PriceSum + this.PriceLoy == 0) {
        this.$alert("แจ้งเตือน", "หน้านี้ยังไม่มีการแทง กดส่งไม่ได้", "error");
        return;
      }
      // console.log(this.PriceSum + this.PriceLoy);

      this.$confirm("ยืนยัน", "กด OK เพื่อส่งโพย", "question")
        .then(() => {
          this.doSendPage(0);
        })
        .catch(() => { });
    },

    OnConfirmBet() {
      //issending
      this.doSendPage(1);
    },

    async doSendPage(p_Confirm) {
      let parameter = {
        to: "Page",
        method: "send_page",
        PeriodDT: this.PeriodDT,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
        Confirm: p_Confirm,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          //   this.CloseTime = response.Period.CloseTime;
          //   this.PeriodStatus = parseInt(response.Period.Status);
          //   this.PeriodIsOpen = parseInt(response.Period.IsOpen);
          //   this.$refs.tblPage.setFocus();
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "HP_IN_PAGE":
              this.$refs.ConfirmHPDlg.Show();
              break;
            case "FULL_NUMBER_IN_PAGE":
              break;
            default:
              break;
          }
        }
      );
    },
    getPriceSign(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return "ก";
      } else if (p_PriceText.indexOf("x") > 0) {
        return "x";
      } else {
        return "";
      }
    },
    getPrice2(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[1];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[1];
      } else {
        return "";
      }
    },
    GetStatusText(status) {
      const statusText = [
        "ลบ",
        "รอส่ง",
        "ส่งแล้ว",
        "ตีกลับ",
        "ถูกรางวัล",
        "จ่ายแล้ว",
      ];
      return statusText[parseInt(status) + 1];
    },
    getStatusColor(status) {
      const statusColor = [
        "red--text",
        "yellow--text text--darken-4",
        "green--text text--darken-1",
        "red--text",
        "light-blue--text",
        "indigo--text",
      ];
      return statusColor[parseInt(status) + 1];
    },
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs.tableDiv.clientHeight;
      console.log(this.dataTableHeight);
    },
    OnAddLine() {
      //Sending = true
      this.$router.push({
        path: "KeyPage",
        query: {
          CusID: this.CusID,
          PageNumber: this.PageNumber,
        },
      });
    },
    OnBackToPages() {
      this.$router.push({
        path: "Pages",
        query: {
          CusID: this.CusID,
        },
      });
    },
    OnShareBtn_Click() {
      this.$router.push({
        path: "SharePage",
        query: {
          PageNumber: this.PageNumber,
          CusID: this.CusID,
        },
      });
    },
    OnPrintBtn_Click() {
      // console.log(this.server);
      // if (this.t == 0) {
      //   this.connectToDevice();
      //   this.t = 1;
      // } else {
      //   this.getUUIDs();
      // }

      this.connectAndPrint();
    },

    async connectAndPrint() {
      try {
        // ขออนุญาตเชื่อมต่อกับอุปกรณ์ Bluetooth ใด ๆ
        this.device = await navigator.bluetooth.requestDevice({
          acceptAllDevices: true,
          optionalServices: [] // คุณสามารถใส่ UUID ของบริการที่คุณต้องการเชื่อมต่อที่นี่
        });
        // เชื่อมต่อกับ GATT server ของอุปกรณ์
        this.server = await this.device.gatt.connect();
        console.log('Connected to device:', this.device);

        // ดึงบริการทั้งหมดจากอุปกรณ์ที่เชื่อมต่อ
        const services = await this.server.getPrimaryServices();
        for (const service of services) {
          console.log(`Service: ${service.uuid}`);
          // ดึงคุณลักษณะ (characteristic) ของแต่ละบริการ
          const characteristics = await service.getCharacteristics();
          for (const characteristic of characteristics) {
            console.log(`Characteristic: ${characteristic.uuid}`);

            // ตรวจสอบว่าคุณลักษณะนี้รองรับการเขียนข้อมูล
            if (characteristic.properties.write) {
              this.printerService = service;
              this.printerCharacteristic = characteristic;
            }
          }
        }

        if (!this.printerCharacteristic) {
          console.log('No writable characteristic found.');
          return;
        }

        // ส่งคำสั่ง ESC/POS เพื่อกำหนด code page เป็น PC874 (TIS-620)
        const setCodePageCommand = new Uint8Array([0x1B, 0x74, 0x26]); // 0x26 คือค่า code page สำหรับ PC874
        await this.printerCharacteristic.writeValue(setCodePageCommand);
        console.log('Code page set to PC874.');

        // พิมพ์ข้อความหลายบรรทัด
        // const encoder = new TextEncoder('utf-8');
        const messages = [
          '',
          'สวัสดี, เครื่องพิมพ์บลูทูธ!',
          'นี่คือบรรทัดใหม่.',
          'และอีกหนึ่งบรรทัด.'
        ];

        for (const message of messages) {
          // const encodedMessage = iconv.encode(message + '\n', 'TIS-620');
          const encodedMessage = iconv.encode(message + '\n', 'win874'); // แปลงข้อความเป็น PC874 (TIS-620)

          // const encodedMessage = encoder.encode(message + '\n');
          await this.printerCharacteristic.writeValue(encodedMessage);
          console.log(`Message "${message}" sent to printer.`);
          // await this.sleep(500); // เพิ่มการหน่วงเวลาเล็กน้อย (0.5 วินาที)
        }
      } catch (error) {
        console.log('Error:', error);
      }
    },

    // async connectAndGetUUIDs() {
    //   try {
    //     // ขออนุญาตเชื่อมต่อกับอุปกรณ์ Bluetooth ใด ๆ
    //     this.device = await navigator.bluetooth.requestDevice({
    //       acceptAllDevices: true,
    //       optionalServices: [] // คุณสามารถใส่ UUID ของบริการที่คุณต้องการเชื่อมต่อที่นี่
    //     });
    //     // เชื่อมต่อกับ GATT server ของอุปกรณ์
    //     this.server = await this.device.gatt.connect();
    //     console.log('Connected to device:', this.device);

    //     // ดึงบริการทั้งหมดจากอุปกรณ์ที่เชื่อมต่อ
    //     const services = await this.server.getPrimaryServices();
    //     let c_uuid = null;
    //     for (const service of services) {
    //       console.log(`Service: ${service.uuid}`);
    //       // ดึงคุณลักษณะ (characteristic) ของแต่ละบริการ
    //       const characteristics = await service.getCharacteristics();
    //       for (const characteristic of characteristics) {
    //         console.log(`Characteristic: ${characteristic.uuid}`);
    //         c_uuid = characteristic.uuid;
    //       }
    //     }

    //     this.printerDevice = await navigator.bluetooth.requestDevice({
    //       filters: [{ services: ['000018f0-0000-1000-8000-00805f9b34fb'] }]
    //     });
    //     //Service: 000018f0-0000-1000-8000-00805f9b34fb
    //     // 00002af0-0000-1000-8000-00805f9b34fb
    //     // 00002af1-0000-1000-8000-00805f9b34fb
    //     const server = await this.printerDevice.gatt.connect();
    //     // this.server = server;
    //     this.printerService = await server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb');
    //     // alert('Connected to printer');
    //     console.log(this.printerService);
    //     console.log('Connected to printer');

    //   } catch (error) {
    //     console.log('Error:', error);
    //   }
    // },

    // async connectToDevice() {
    //   try {
    //     this.device = await navigator.bluetooth.requestDevice({
    //       acceptAllDevices: true,
    //       optionalServices: [] // ใส่ UUID ของบริการที่คุณต้องการเชื่อมต่อที่นี่
    //     });
    //     this.server = await this.device.gatt.connect();
    //     console.log('Connected to device:', this.device);


    //   } catch (error) {
    //     console.log('Error connecting to device:', error);
    //   }
    // },
    // async getUUIDs() {
    //   if (!this.server) {
    //     console.log('Device not connected');
    //     return;
    //   }
    //   try {
    //     const services = await this.server.getPrimaryServices();
    //     for (const service of services) {
    //       console.log(`Service: ${service.uuid}`);
    //       const characteristics = await service.getCharacteristics();
    //       for (const characteristic of characteristics) {
    //         console.log(`Characteristic: ${characteristic.uuid}`);
    //       }
    //     }
    //   } catch (error) {
    //     console.log('Error getting UUIDs:', error);
    //   }
    // },
    // async connectToPrinter() {

    //   // console.log(navigator.bluetooth)
    //   try {
    //     this.printerDevice = await navigator.bluetooth.requestDevice({
    //       filters: [{ services: ['000018f0-0000-1000-8000-00805f9b34fb'] }]
    //     });
    //     //Service: 000018f0-0000-1000-8000-00805f9b34fb
    //     // 00002af0-0000-1000-8000-00805f9b34fb
    //     // 00002af1-0000-1000-8000-00805f9b34fb
    //     const server = await this.printerDevice.gatt.connect();
    //     // this.server = server;
    //     this.printerService = await server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb');
    //     // alert('Connected to printer');
    //     console.log(this.printerService);
    //     console.log('Connected to printer');
    //   } catch (error) {
    //     console.error('Error connecting to printer:', error);
    //     // alert('Error connecting to printer:' +  error);
    //   }
    // },
    async print() {
      if (!this.printerService) {
        console.log('No printer connected');
        this.connectToPrinter();
        return;
      }
      // console.log(this.printerServer.getPrimaryServices());



      // ส่งข้อมูลการพิมพ์ไปยังเครื่องพิมพ์
      // console.log('Sending data to printer:', this.printData);

      try {
        const printerCharacteristic = await this.printerService.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb');
        const encoder = new TextEncoder();
        const message = encoder.encode('Hello, Bluetooth Printer!');
        await printerCharacteristic.writeValue(message);
      } catch (error) {
        console.log('Error printing message:', error);
      }

    }

  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
    CusID: "",
    PageNumber: 0,

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,

    dataTableHeight: 0,

    device: null,
    server: null,
    printerService: null,
    printerCharacteristic: null
  }),
};
</script>

<style scoped>
.container {
  background-color: white;
  flex-grow: 1;
  flex-shrink: 0;
}

.layout {
  flex-flow: column;
  padding: 2px;
}

.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}

.row_content {
  background-color: white;
  flex: 3 0px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
}

.row_footer {
  background-color: white;
  flex: 0 1 40px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.header_bg {
  background-color: whitesmoke;
  color: black;
  font-size: 4.5vw;
  padding-top: 0;
}

.line {
  height: 2px;
  width: 100%;
  background-color: black;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
</style>
