import { render, staticRenderFns } from "./Popup_Menu.vue?vue&type=template&id=4865ae8c&scoped=true"
import script from "./Popup_Menu.vue?vue&type=script&lang=js"
export * from "./Popup_Menu.vue?vue&type=script&lang=js"
import style0 from "./Popup_Menu.vue?vue&type=style&index=0&id=4865ae8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4865ae8c",
  null
  
)

export default component.exports