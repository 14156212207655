<template>
  <v-layout
    style="
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
      padding: 0;
    "
    fluid
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :items="lines_data"
      item-key="id"
      single-select
      class="add_table elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      :height="tableheight"
      dense
    >
      <template slot="no-data"
        ><span class="red--text">ว่างเปล่า</span> กด
        <span class="blue--text">เพิ่มบรรทัด</span> เพื่อ
        <span class="green--text">คีย์เลข</span></template
      >
      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr
          @click="row_click($event, item)"
          :class="{ selected_class: item.id === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->

          <td width="6%" class="id_column border_class pl-1 pr-0">
            {{ item.id }}.
          </td>
          <td
            align="start"
            width="10%"
            class="bettype_column border_class pl-2 pr-0"
            :class="getBetTypeColor(item.BetTypeID)"
          >
            {{ getBetTypeName(item.BetTypeID) }}
          </td>
          <td align="end" class="betnumber_column pl-1 pr-1">
            {{ item.Number }}
          </td>
          <td class="equal_column pa-0 blue--text">=</td>
          <td align="end" class="price1_column pl-1 pr-2">
            {{ add_comma(getPrice1(item.PriceText)) }}
          </td>
          <td class="sign_column pa-0 blue--text">
            {{ getPriceSign(item.PriceText) }}
          </td>
          <td align="end" class="price2_column border_class pl-2 pr-1">
            {{ add_comma(getPrice2(item.PriceText)) }}
          </td>
          <td
            align="end"
            class="status_column pl-1 pr-2"
            :class="getStatusColor(item.Status)"
          >
            {{ getStatusText(item.Status) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <PopupMenu ref="PopupMenu" @del="OnDeleteLine" />
    <!-- <v-card :height="(tableheight - toolbarHeight - 12)/2">
      <v-card-title>เพิ่มบรรทัด</v-card-title>
    </v-card> -->
  </v-layout>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import betFunctionMixin from "@/mixins/betFunctionMixin";

import PopupMenu from "@/components/Pages/Page/Popup_Menu";

export default {
  name: "PageTable",
  mixins: [globalFunctionMixin, betFunctionMixin],
  components: {
    PopupMenu,
  },

  props: {
    tableheight: {
      type: Number,
      default: 600, //default v-data-table footer height
    },
    CusID: {
      type: String,
      default: "",
    },
    PageNumber: {
      type: Number,
      default: 0,
    },
    UpdateDT: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // this.$nextTick(() => {
    // });
  },
  computed: {
    lines_data() {
      return this.$store.getters["page/LineList"];
    },
  },
  methods: {
    async OnDeleteLine(LineNumber) {
      console.log("Delete:" + LineNumber);
      //   console.log("OnSendLine");
      let parameter = {
        to: "KeyPage",
        method: "del_line",
        PeriodDT: this.PeriodDT,
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
        LineNumber: LineNumber,
        LastedUpdate: this.UpdateDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          //   this.SetLastLine(this.$store.getters["page/LineList"].slice(-1)[0]);
        },
        (error) => {}
      );
    },

    setFocus() {
      if (this.$store.getters["page/SelectedID"] != -1) {
        // console.log("page/SetSelectedID");
        // console.log(this.$store.getters["page/SelectedID"]);
        this.selectedId = this.$store.getters["page/SelectedID"];
        this.$store.commit("page/SetSelectedID", -1);

        // this.$store.commit("page/SetScrollPageTable", this.scrollTop);

        setTimeout(() => {
          var container = document.querySelector(
            ".add_table .v-data-table__wrapper"
          );
          // var scrollHeight = container.scrollHeight;
          //   console.log("container.scrollTop");
          //   console.log(this.$store.getters["page/ScrollPageTable"]);
          container.scrollTop = this.$store.getters["page/ScrollPageTable"];
          // this.$store.commit("page/setScrollPageTable", -1);
          this.$store.commit("page/SetScrollPageTable", -1);
        }, 500);
      }
    },
    getPrice1(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[0];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[0];
      } else {
        return p_PriceText;
      }
    },
    getPriceSign(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return "ก";
      } else if (p_PriceText.indexOf("x") > 0) {
        return "x";
      } else {
        return "";
      }
    },
    getPrice2(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[1];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[1];
      } else {
        return "";
      }
    },
    getBetTypeColor(p_BetType) {
      switch (p_BetType) {
        case "1": //บน
        case "6": //บน/น";
        case "7": //บน/ก";
        case "8": //บน/ท";
        case "11": //บน/น";
        case "12": //บน/ถ";
        case "13": //ล.บ.";
        case "15": //19/บ";
        case "17": //ค.ต.";
          return "blue--text text--darken-3";

        case "2": // ล่าง
        case "4": //พวง
        case "5": //3 ล่าง
        case "9": //ล่าง/น";
        case "10": //ล่าง/ท";
        case "14": //ล.ล";
        case "16": //19/ล";
          return "red--text text--darken-1";

        case "3": //บ+ล
          return "grey--text text--darken-3";

        case "18": //3น.";
        case "19": //3น.";
        case "20": //3น/น";
        case "21": //3น/ก";
        case "22": //3น/ท";
        case "23": //3น/น";
        case "24": //3น/ถ";
        case "25": //3น.";
        case "26": //ต/3น.";
          return "yellow--text text--darken-3";
      }
    },
    getStatusText(p_Status) {
      const statusText = [
        "ปกติ",
        "ลบ",
        "แก้ไข",
        "อั้น",
        "ตีกลับ",
        "ถูก",
        "เลขเต็ม",
      ];
      return statusText[parseInt(p_Status)];
    },
    getStatusColor(p_Status) {
      const statusColor = [
        "grey--text text--darken-3",
        "red--text",
        "deep-orange--text text--accent-4",
        "deep-orange--text text--accent-4",
        "red--text",
        "amber--text text--darken-3",
        "red--text",
      ];
      return statusColor[parseInt(p_Status)];
    },
    row_click: function (e, item) {
      if (this.selectedId == item.id) {
        this.popup_menu(e, item);
      }

      this.selectedId = item.id;
      this.$set(item, "selected", true);
    },
    popup_menu: function (e, item) {
      e.preventDefault();
      this.$refs.PopupMenu.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        var container = document.querySelector(
          ".add_table .v-data-table__wrapper"
        );
        //   var scrollHeight = container.scrollHeight;
        //   console.log(container.scrollTop);
        //   container.scrollTop = scrollHeight;

        this.$refs.PopupMenu.Show(
          this.PageNumber,
          this.CusID,
          item.LineNumber,
          parseInt(this.$store.getters["page/PageData"].Status),
          item.id,
          this.getBetTypeName(item.BetTypeID),
          item.Number,
          item.PriceText,
          container.scrollTop
        );
        this.selectedId = item.id;
        //console.log(item);
      });
    },
  },
  data: () => ({
    // SYSTEM
    // toolbarHeight: 0,
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
    ///////////////
    selectedId: -1,
    PageStatus: 0,
  }),
};
</script>

<style scoped>
.toolbar__items {
  flex-wrap: wrap;
}
::v-deep table {
  background-color: #ffffff;
  /* table-layout: fixed; */
}
::v-deep tr:nth-child(odd) {
  background-color: #fafafa;
}
::v-deep table tbody tr td {
  font-size: 4.3vw !important;
  color: rgb(88, 88, 88);
  font-weight: bold;
}
.id_column {
  /* width: 6% !important;
  border-right: 1px solid rgb(190, 197, 255); */
  padding: 0 0;
}
.bettype_column {
  width: 15% !important;
  /* background-color: blue; */
  /* padding: 0 5px !important; */
}
.betnumber_column {
  width: 15% !important;
  /* padding: 0 5px !important; */
}
.equal_column {
  width: 2% !important;
  /* background-color: grey; */
  /* padding: 0 10px !important; */
}
.price1_column {
  width: 20% !important;
  /* background-color: blue; */
  /* padding: 0 0px !important; */
}
.sign_column {
  width: 2% !important;
  /* padding: 0 8px !important; */
}
::v-deep .border_class {
  border-right: 1px solid rgb(236, 236, 236);
}
.selected_class {
  background-color: pink !important;
  /* color: white !important; */
}
</style>
