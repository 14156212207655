<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <!-- <div class="white accent-2 pink--text text-center py-2" style="font-size: large;"> <span class="mr-2">ใบที่</span>  {{ id }}</div>
    <v-divider></v-divider> -->
      <v-list dense>
        <v-list-item link @click="menuActionClick('detail')">
          <v-list-item-icon>
            <v-icon color="success" class="py-1">mdi-eye</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >ดู</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('edit')">
          <v-list-item-icon>
            <v-icon color="blue" class="py-1">mdi-lead-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >แก้ไข</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('del')" v-if="Status == 0">
          <v-list-item-icon>
            <v-icon class="py-1" color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >ลบ</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('exit')">
          <v-list-item-icon>
            <v-icon color="amber darken-4" class="py-1"
              >mdi-location-exit</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >ออก</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",

  //   props: [
  //     "Status", //เปิดใช้งาน ระงับ
  //   ],

  methods: {
    Show(
      page_number,
      cus_id,
      line_number,
      Status,
      id,
      bet_type,
      bet_number,
      pricetext,
      scrollTop
    ) {
      this.showMenu = true;
      this.PageNumber = page_number;
      this.LineNumber = line_number;
      this.CusID = cus_id;
      this.Status = Status;
      this.id = id;
      this.BetNumber = bet_number;
      (this.BetType = bet_type), (this.PriceText = pricetext.replace(".", "ก"));
      this.scrollTop = scrollTop;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        // case "cus":
        //   this.$router.push({
        //     path: "agent_data",
        //     query: { line_id: this.id },
        //   });
        //   //this.$router.push("/Agent_Data");
        //   break;
        case "del":
          this.$confirm(
            "ยืนยันลบบรรรทัด",
            this.id +
              ". " +
              this.BetType +
              " " +
              this.BetNumber +
              " = " +
              this.PriceText,
            "question"
          )
            .then(() => {
              this.$emit("del", this.LineNumber);
            })
            .catch(() => {});
          break;

        case "detail":
          alert("detail");
          break;
        case "edit":
          this.$store.commit("page/SetSelectedID", this.id);
          this.$store.commit("page/SetScrollPageTable", this.scrollTop);

          this.$router.push({
            path: "KeyPage",
            query: {
              CusID: this.CusID,
              PageNumber: this.PageNumber,
              EditLine: this.LineNumber,
            },
          });
          break;
        case "block":
          alert("block");
          break;
        case "exit":
          this.showMenu = false;
          break;
      }
    },
  },
  data: () => ({
    id: null,
    showMenu: false,
    x: 0,
    y: 0,
    CusID: "",
    PageNumber: 0,
    LineNumber: 0,
    Status: 0, //0=เปิด 1=ปิด
    BetType: "",
    BetNumber: "",
    PriceText: "",
    scrollTop: 0,
  }),
};
</script>

<style scoped>
/* ::v-deep v-list-item-title {
  font-size: 4.3vw !important;
} */
* {
  /* font-size: 4vw !important;  */
  /* ให้ขนาด font เป็น 2% ของความกว้างของหน้าจอ */
}
</style>
